import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"

const PrivacyPolicy = () => {
  return (
    <>
      <Layout>
        <Seo
          title="Privacy Policy "
          keyword="privacy policy"
          description=" Read Atyeti's Privacy Policy, adopted to respect and ensure your privacy rights."
        />
        <Navbar />
        <div className="container pt-160 pb-80">
          <div className="row d-flex justify-content-center privacy-policy">
            <div className="col-lg-12 col-md-12">
              <h1>Privacy Policy</h1>
              <h3>Objective:</h3> 
              <p>
                This Privacy Statement articulates the privacy and data
                protection principles followed by Atyeti and its entities around
                the world with regards to the personal information of its
                customers (including products, outsourcing and other services
                clients), partners, employees (current or former employees,
                trainees), applicants, contractors, prospects and vendors and
                current or former members of the Board of Directors, whose
                personal information are processed by Atyeti (“Data Subjects”).
              </p>
              <p>
                This Privacy Statement explains the use and protection of
                personal information collected by Atyeti. It applies to any
                personal information you provide to Atyeti and, subject to local
                law, any personal information we collect from other sources.
              </p>
              <h3>Scope of &nbsp;this Privacy Statement:</h3>
              <p>
                This Privacy Statement covers our information practices,
                including how we collect, use, share and protect the personal
                information you provide to us, through our web sites that link
                to this Privacy Statement (collectively referred to as “Atyeti’s
                Web sites”).
              </p>
              <p>
                Atyeti may provide links to third-party websites for your
                convenience and information. If you access those links through
                the Atyeti website, you will leave the Atyeti website. Atyeti
                does not control those sites or their privacy practices, which
                may differ from Atyeti’s practices. We do not endorse or make
                any representations about third-party websites. The personal
                information you choose to provide to or that is collected by
                these third parties, including any social media websites
                featured on our website, is not covered by the Atyeti’s Privacy
                Statement. We encourage you to review the privacy statement or
                policy of any website before submitting your personal
                information.
              </p>
              <p>
                We may also provide social media features on our website that
                enable you to share Atyeti information with your social networks
                and to interact with Atyeti on various social media sites. Your
                use of these features may result in the collection or sharing of
                information about you, depending on the feature. We encourage
                you to review the privacy policies and settings on the social
                media sites with which you interact to make sure you understand
                how the information provided by you could be used or shared by
                those sites.
              </p>
              <h3>Personal information collected by Atyeti:</h3>
              <p>
                We may collect personal information such as your First Name,
                Last Name, E-mail Address, Job Title, Organization Name,
                Country, City and Phone Number.&nbsp; When you provide
                information that enables us to respond to your request for
                products or services, we will, wherever permissible by relevant
                laws, collect, use and disclose this information to third
                parties for the purposes described in this Privacy Statement.
              </p>
              <h4>
                Personal information that you provide to Atyeti directly through
                websites or emails
              </h4>
              <p>
                We may collect personal information such as your First Name,
                Last Name, E-mail Address, Job Title, Organization Name,
                Country, City and Phone Number when you fill forms on or raise a
                query through our websites, or send emails, call us or write to
                us.
              </p>
              <h4>
                Personal information that is automatically collected by Atyeti
                when you use Atyeti websites
              </h4>
              <h5>
                <em>Visit Atyeti Websites</em>
              </h5>
              <p>
                In some instances, Atyeti automatically collects personal
                information pertaining to you when you visit our websites and
                through e-mails that we may exchange. We may use automated
                technologies including the use of web server logs to collect IP
                addresses, device details, cookies and web beacons. The
                collection of this information will allow us to improve the
                effectiveness of Atyeti websites and our marketing activities.
                Please see the section below on cookies for further details.
              </p>
              <p>
                By using Atyeti’s websites, associated microsites and mobile
                applications, you agree to the processing of your information as
                explained in this Privacy Statement.
              </p>
              <p>
                Atyeti websites may contain links to other sites which are
                outside our control and are not covered by this Privacy
                Statement. We encourage you to review the Privacy
                Statement/Notice of each website you visit.
              </p>
              <h4>
                Personal information that is collected from third party sources
              </h4>
              <h5>
                <em>Engage with Atyeti through social media</em>
              </h5>
              <p>
                You can engage with us through social media websites or through
                features on Atyeti websites that integrate with social media
                sites. When you engage with us through social media sites, you
                may allow us to have access to certain information from your
                social media profile based upon your privacy preference settings
                on such platform.
              </p>
              <h4>
                Personal information that is collected when you access our
                Websites through mobile devices
              </h4>
              <h5>
                <em>Access our Websites through Mobile Devices</em>
              </h5>
              <p>
                If you access our websites on your mobile device, we may collect
                your personal information such as your First Name, Last Name,
                E-mail Address, Job Title, Organization Name, Country, City,
                Phone Number, IP address and Device details.
              </p>
              <p>
                When you provide us your mobile phone number, you consent to the
                use of your mobile phone number for the purposes identified in
                this Privacy Statement. If you choose to refrain from receiving
                any text notifications from us on your mobile, we will not be
                using your mobile number for such purposes unless required to
                meet legal requirements or legitimate business purposes. We do
                not collect more information than we need to fulfill our stated
                purposes and will not retain it for longer than is necessary.
              </p>
              <h3>How We Use your Data:</h3>
              <p>The personal information we collect may be used to:</p>
              <ul>
                <li>provide information and services as requested by you;</li>
                <li>
                  assess queries, requirements, and process requests for
                  products and services;
                </li>
                <li>
                  perform client communication, service, billing and
                  administration;
                </li>
                <li>conduct data analysis;</li>
                <li>assess web site performance and usage analysis</li>
                <li>maintain leads</li>
                <li>run marketing or promotional campaigns</li>
                <li>create brand awareness</li>
                <li>provide better services and generate demand</li>
                <li>
                  market products and services based on legitimate business
                  interest under the applicable law); or
                </li>
                <li>
                  conduct processing necessary to fulfill other contractual
                  obligations for the individual.
                </li>
              </ul>
              <p>
                With your consent, we may also use your personal information for
                additional purposes. Instances where sensitive personal
                information is collected will warrant explicit consent. Unless
                it is necessary for our legitimate business purposes, should you
                choose not to provide any personal information during certain
                activity, you will nevertheless be able to continue with such
                activity wherever possible.
              </p>
              <h3>Sharing of personal information with the third parties:</h3>
              <p>
                Atyeti does not share personal information about you with
                affiliates, partners, service providers, group entities and
                non-affiliated companies except: (a) to provide products or
                services you’ve requested; (b) when we have your permission: or
                (c) under the following circumstances:
              </p>
              <ul>
                <li>
                  We may share information with affiliated
                  entities/subsidiaries/branch offices for legitimate business
                  purposes.
                </li>
                <li>
                  We may provide the information to trusted entities who work on
                  behalf of or with Atyeti under strict confidentiality
                  agreements. These entities may use your personal information
                  to help Atyeti communicate with you about offers from Atyeti
                  and for other legitimate business purposes. However, these
                  companies do not have any independent right to further share
                  or disseminate this information;
                </li>
                <li>
                  We may share information with statutory authorities,
                  government institutions or other bodies where required for
                  compliance with legal requirements.
                </li>
                <li>
                  We may use the information to respond to subpoenas, court
                  orders, or legal process, or to establish or exercise our
                  legal rights or defend against legal claims;
                </li>
                <li>
                  We may share information where we believe it is necessary in
                  order to investigate, prevent, or take action against any
                  illegal activities, suspected fraud, situations involving
                  potential threats to the physical safety of any person, or as
                  otherwise required by law.
                </li>
                <li>
                  We may share information where we believe it is necessary in
                  order to protect or enforce Atyeti’s rights, usage terms,
                  intellectual or physical property or for safety of Atyeti or
                  associated parties.
                </li>
                <li>
                  We may share information about you if Atyeti is acquired by or
                  merged with another company.
                </li>
              </ul>
              <p>
                You may choose to give us personal information such as your name
                and address or e-mail identification address (‘ID’) that may be
                needed, for example, to correspond with you, for you to download
                our white papers, to provide you with subscription information
                etc. We will endeavor to protect the quality and integrity of
                your personally identifiable information.
              </p>
              <p>
                Atyeti will not be liable for any unsolicited information
                provided by you. You consent to Atyeti using such information as
                per Atyeti’s Privacy Statement.
              </p>
              <p>
                We will make a sincere effort to respond in a timely manner to
                your requests to correct inaccuracies in your personal
                information. To correct inaccuracies in your personal
                information, please return the message containing the
                inaccuracies to the sender.
              </p>
              <p>
                Atyeti’s websites are not directed at nor targeted at children.
                No one who has not reached the age of thirteen may use the
                websites unless supervised by an adult.
              </p>
              <p>
                By accessing this website you represent and warrant that you are
                13 years of age or older.
              </p>
              <h3>Preferences:</h3>
              <p>
                Atyeti takes reasonable steps to keep your personal information
                accurate and complete. You can access or update your personal
                information in the following ways.
              </p>
              <h5>Profile</h5>
              <p>
                If you have created a profile or account on one of our websites,
                you can update your contact information after you log into your
                account.
              </p>
              <h5>Newsletters</h5>
              <p>
                If you request electronic communications, such as an
                e-newsletter, you will be able to unsubscribe at any time by
                following the instructions included in the communication.
              </p>
              <h5>Mobile Devices</h5>
              <p>
                If you previously chose to receive push notifications on your
                mobile device from us but no longer wish to receive them, you
                can manage your preferences either through your device or the
                application settings. If you no longer wish to have any
                information collected by the mobile application, you may
                uninstall the application by using the uninstall process
                available on your mobile device.
              </p>
              <h3>Cookies and other technologies</h3>
              <p>
                A cookie is a small file, typically of letters and numbers,
                downloaded to a user’s computer or mobile device when the user
                accesses certain websites. Cookies are then sent back to
                originating website on each subsequent visit. Cookies are useful
                because they allow a website to recognize a user’s device. They
                are used for a wide variety of purposes such as website
                analytics – counting visitors and their behavior, targeted
                advertising, recording user preferences or for authentication.
              </p>
              <h4>Necessary or Essential Cookies</h4>
              <p>
                Cookies which are absolutely necessary for the running of a
                website. These are generally first-party cookies are usually
                exempted from the consent requirement. E.g. Authentication
                cookies, Session cookies etc.
              </p>
              <h4>Functional Cookies</h4>
              <p>
                Cookies which are set up to improve the functionality of the
                site or track/monitor user behavior, preferences etc. These
                cookies are non-essential a website can run without usage of
                such cookies. E.g. Language preference, site behavior for a
                registered user, tracking of user, analytics, social plug-in
                etc. These cookies can be first-party or third-party cookies.
              </p>
              <p>
                We use cookies on our websites to personalize content and ads,
                to analyze our traffic and performance, to provide social media
                features and for the functioning of the website.
              </p>
              <p>
                We also disclose information about your use of our site to our
                social media/advertising/analytics partners who may combine it
                with other information that you have provided to them or which
                they have collected from your use of their services. You consent
                to our cookies if you continue to use this website.
              </p>
              <p>
                You can manage the non-essential or functional cookies in your
                browser. These settings are usually found within the ‘privacy’
                section of browsers.
              </p>
              <div>
                Chrome:&nbsp;
                <a href="https://support.google.com/chrome/answer/95647">
                  https://support.google.com/chrome/answer/95647
                </a>
                <br />
                Firefox:&nbsp;&nbsp;
                <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                  https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                </a>
                <br />
                IE:&nbsp;&nbsp;
                <a href="https://support.microsoft.com/en-in/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/en-in/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
              </div>
              <p>
                You can choose to accept or decline cookies, either by selecting
                accept or decline in the banner on your first visit (in the
                latter case we will try to set a cookie to record your
                preference for future visits), or by changing the settings in
                your web browser. Most web browsers are set by accept cookies by
                default, but you can usually modify your browser settings to
                decline cookies if you prefer. These settings are usually found
                within the ‘privacy’ section. For mobile devices these may be
                within the browser app settings.
              </p>
              <p>
                If you choose not to accept our various cookies, this will
                prevent you from taking full advantage of the website. If you
                wish to delete cookies which have already been placed on your
                computer’s hard drive, you can do so through your browser, and
                further information on how to do this can be found
                here:&nbsp;&nbsp;
                <a href="https://www.aboutcookies.org/page/2/">
                  https://www.aboutcookies.org/page/2/
                </a>
              </p>
              <p>
                We also use web beacons or tracking pixels in our emails and web
                pages which help us to monitor whether you have opened any
                newsletters we may have sent to you. A web beacon is one of
                various techniques used on web pages or email, to unobtrusively
                (usually invisibly) allow checking that a user has accessed some
                content. Common uses are email tracking and page tagging for web
                analytics. We use this information to deliver you a personalized
                service by ensuring that we send you information that we believe
                you will find interesting based on the content we know you have
                engaged with previously.
              </p>
              <p>
                Please refer to the links provided below to know more about the
                third-party analytics cookies used on Atyeti websites
              </p>
              <h5>Google Analytics Cookies:</h5>
              <div>
                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                  https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
                </a>
              </div>
              <p>
                To provide website visitors with more choice on how their data
                is collected by Google Analytics, Google have developed
                the&nbsp;
                <a href="https://tools.google.com/dlpage/gaoptout">
                  Google Analytics Opt-out Browser Add-on
                </a>
                . The add-on communicates with the Google Analytics JavaScript
                (ga.js) to indicate that information about the website visit
                should not be sent to Google Analytics. The Google Analytics
                Opt-out Browser Add-on does not prevent information from being
                sent to the website itself or to other web analytics services.
              </p>
              <h5>Adobe Analytics Cookies:</h5>
              <div>
                <a href="https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_analytics.html">
                  https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_analytics.html
                </a>
              </div>
              <p>
                Our website also includes functionality to interact with social
                media websites where you may have accounts, such as Facebook,
                Twitter and LinkedIn (“Third Party Accounts”).&nbsp; You should
                be aware that these sites may set cookies while you’re using
                this website, and use of these features may result in the
                collection or sharing of information about you. We encourage you
                to review your privacy settings on the social media sites with
                which you interact, together with their privacy policies, to
                make sure you understand the information that could be shared by
                those sites. The following Pixel/Tags are used for social media
              </p>
              <ul>
                <li>
                  LinkedIn Insights Tag for Conversion Tracking.&nbsp;
                  <a href="https://www.linkedin.com/legal/sas-terms#additional-terms-for-optional-conversion-tracking">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/business/help/651294705016616?helpref=faq_content">
                    Facebook Pixel
                  </a>
                  &nbsp; –&nbsp;
                  <a href="https://www.facebook.com/customaudiences/app/tos/?ref=u2u">
                    Terms and Conditions
                  </a>
                  &nbsp;for use
                </li>
                <li>
                  Twitter&nbsp;
                  <a href="https://legal.twitter.com/ads-terms/us.html#twitteradsprogramt%26cs">
                    Tailored Audiences
                  </a>
                  and&nbsp;
                  <a href="https://legal.twitter.com/ads-terms/us.html#twitterconversiontrackingprogramt%26cs">
                    Conversion Tracking
                  </a>
                  &nbsp;
                  <a href="https://business.twitter.com/en/help/ads-policies/other-policy-requirements/policies-for-conversion-tracking-and-tailored-audiences.html">
                    Terms and Conditions
                  </a>
                  .
                </li>
              </ul>
              <h3>Email:</h3>
              <p>
                You can contact us at the e-mail or postal address listed in the
                “Contact Us” section at the bottom of this Statement. Please
                include your current contact information, the information you
                are interested in accessing and your request with regard to the
                same if any. We will endeavor to respond to your request,
                subject to reasonable limitations provided by law, unless it
                infringes on the privacy of other individuals.
              </p>
              <p>
                If we are unable to entertain your request, we will provide you
                with the reason for refusal and inform you of any exceptions
                relied upon.
              </p>
              <h3>Anti-Spam policy:</h3>
              <p>
                Atyeti recognizes the receipt, transmission or distribution of
                spam emails (i.e. unsolicited bulk emails) as a major concern
                and has taken reasonable measures to minimize the transmission
                and effect of spam emails in our computing environment. All
                emails received by Atyeti are subject to spam check. Any email
                identified as spam will be rejected with sufficient information
                to the sender for taking necessary action. With this measure,
                along with other technical spam reduction measures, Atyeti hopes
                to minimize the effect of spam emails. Atyeti reserves the right
                to reject and/or report any suspicious spam emails, to the
                authorities concerned, for necessary action.
              </p>
              <h3>Data Protection and Confidentiality:</h3>
              <p>
                Atyeti has implemented reasonable and adequate physical,
                technical and administrative security measures to protect
                personal information from loss, misuse, alteration or
                destruction.
              </p>
              <p>
                Atyeti discloses information to third parties only for
                legitimate purposes such as our service providers and agents who
                are bound to maintain the confidentiality of personal
                information and may not use the information for any unauthorized
                purpose. Atyeti will ensure through formally executed contracts
                that the service providers are committed to “same level of data
                protection” as applicable data protection laws and regulations.
              </p>
              <p>
                Further, Atyeti may make disclosures to law enforcement
                authorities or regulatory bodies subject to applicable law.
              </p>
              <h4>
                Atyeti will take reasonable steps to ensure that all dealings in
                Personal Information, shall:
              </h4>
              <ul>
                <li>be processed fairly &amp; lawfully;</li>
                <li>
                  be obtained only with the consent of the information provider;
                </li>
                <li>
                  be collected, only when:
                  <ul>
                    <li>
                      the information is collected for a lawful purpose
                      connected with a function or activity of Atyeti; and
                    </li>
                    <li>
                      the collection of the sensitive personal data or
                      information is considered necessary for that purpose.
                    </li>
                  </ul>
                </li>
                <li>
                  be available for review by the information provider, as and
                  when requested.
                </li>
                <li>
                  be kept secure against unauthorized or unlawful processing and
                  against accidental loss, destruction or damage, as per the
                  prescribed standards.
                </li>
              </ul>
              <h4>
                Your (“Data Subjects”) rights with respect to your personal
                information:
              </h4>
              <p>
                We respect your right to be informed, access, correct, request
                deletion or request restriction, portability, objection, and
                rights in relation to automated decision making and profiling,
                in our usage of your personal information as required by
                applicable law. We also take steps to ensure that the personal
                information we collect is accurate and up to date.
              </p>
              <ul>
                <li>
                  You have the right to know what personal information we
                  maintain about you
                </li>
                <li>
                  We will provide you with a copy of your personal information
                  in a structured, commonly used and machine-readable format on
                  request
                </li>
                <li>
                  If your personal information is incorrect or incomplete, you
                  have the right to ask us to update it
                </li>
                <li>
                  You have the right to object to our processing of your
                  personal information
                </li>
                <li>
                  You can also ask us to delete or restrict how we use your
                  personal information, but this right is determined by
                  applicable law and may impact your access to some of our
                  services
                </li>
                <li>
                  You can have the right to access your personal information
                </li>
                <li>
                  You have a right to object to processing of your personal
                  information where it is so conducted by automated means and
                  involves any kind of decision-making
                </li>
              </ul>
              <h4>Right to opt out:</h4>
              <p>
                Atyeti respects your privacy considerations and hence provides
                an option to you, to not provide the data or information sought
                to be collected.&nbsp;Further, you can also withdraw your
                consent which was earlier given to Atyeti, and the same must be
                communicated to Atyeti in writing.
              </p>
              <h4>Access and rectification:</h4>
              <p>
                You have a right to request access to your personal information.
                You may send us a request for access.&nbsp;You also have the
                right to correct any inaccuracies in your personal information.
                If you have an account with the Atyeti Site, this can usually be
                done through the appropriate “your account” or “your profile”
                section on the website Otherwise, you can send us a request to
                rectify your data by writing to us at&nbsp;
                <a href="mailto:atyeti-dataprivay@atyeti.com">
                  atyeti-dataprivay@atyeti.com
                </a>
                .&nbsp;Further, you have the option to file a complaint for
                suspected or actual violations of your data protection rights
                with the relevant supervisory authority.
              </p>
              <h4>Retention of personal information:</h4>
              <p>
                We will retain your personal information for as long as
                necessary to provide the services you have requested, or for
                other essential purposes such as performance of a contract,
                complying with our legal obligations, resolving disputes, and
                enforcing our policies.
              </p>
              <h4>Disclosure of information:</h4>
              <p>
                Atyeti may share your personal information with your consent, or
                where the disclosure is necessary for compliance of a legal
                obligation or where required by government agencies mandated
                under law to procure such disclosure. It shall also take steps
                to ensure that the information transferred to a third party is
                not further disclosed by it except where permissible under law.
              </p>
              <h4>International transfer of Personal Information:</h4>
              <p>
                Atyeti may transfer certain personal information across
                geographical borders to Atyeti entities or service providers in
                other countries working on our behalf in accordance with
                applicable law. &nbsp;A Transfer of personal information has a
                broad meaning and occurs when information is communicated,
                moved, accessed or otherwise sent to another country.&nbsp;In
                cases where personal information would need to be transferred
                outside of a country where Atyeti operates, towards other
                jurisdictions, specific requirements may apply for such Transfer
                to be allowed.
              </p>
              <p>
                For instance, data protection laws of the EEA and of Switzerland
                authorize the Transfer of personal information:
              </p>
              <ul>
                <li>
                  to countries which have been recognized by the European
                  Commission as offering an adequate level of protection for
                  personal information.
                </li>
                <li>
                  if appropriate measures in compliance with the European Union
                  data protection legal requirements are in place in order to
                  ensure the protection and the confidentiality of personal
                  information in the countries that do not offer an adequate
                  level of protection.
                </li>
              </ul>
              <p>
                This is the case where the recipient Atyeti entity or Third
                Party concludes with the exporting relevant entity of Atyeti an
                agreement based on the European Union standard contractual
                clauses, to ensure the protection of personal information.
              </p>
              <p>
                Atyeti may also Transfer personal information to a Third Party
                located outside the EEA or Switzerland without having to
                implement the above measures where one of the following
                conditions is met:
              </p>
              <ul>
                <li>
                  the Data Subject has given her/his consent to Atyeti for the
                  Transfer her/his Personal Information;
                </li>
                <li>
                  Atyeti needs to carry out the transfer of personal information
                  to perform or conclude a contract with the Data Subject; the
                  transfer of personal information is necessary (i) to protect
                  the Data Subject’s vital interests (i.e. in case of a life or
                  death situation), or&nbsp; (ii) to allow Atyeti to establish,
                  exercise or defend a legal claim, or (iii)&nbsp; for reasons
                  of public interest;
                </li>
                <li>
                  the Transfer covers personal information publicly available
                  (for instance from a public register).
                </li>
              </ul>
              <p>
                Atyeti ensures the “same level of data protection” as per
                applicable data protection laws and regulations when it
                transfers your personal information to any other body corporate
                or a person in any country
              </p>
              <h4>Authenticity:</h4>
              <p>
                Atyeti shall not be responsible for the authenticity of the
                personal information supplied to it by its service providers,
                whose duty it is to ensure that the information provided by it
                or its employees is authentic.
              </p>
              <h3>Contact us:</h3>
              <p>
                If you have any questions or comments regarding this Privacy
                Statement or processing of your personal information, please
                send an email to
                <a href="mailto:atyeti-dataprivay@atyeti.com">
                  atyeti-dataprivay@atyeti.com
                </a>{" "}
                &nbsp;or contact us by writing to
              </p>
              <blockquote>
                USA – Princeton
                <br />
                Atyeti Inc
                <br />
                125 Village Blvd, Suite 320
                <br />
                Princeton NJ 08540
                <br />
                Telephone: 609 921 9539
                <br />
                Fax: 609 921 9514
              </blockquote>
              <h3>Grievance Redressal:</h3>
              <p>
                Any grievance or complaint, in relation to processing of
                personal information, should be sent to Atyeti in writing to
                following contact email ID. Grievance shall be redressed as
                expeditiously as possible.&nbsp;Contact details are as follows:
              </p>
              <blockquote>
                E-mail:&nbsp;
                <a href="mailto:atyeti-dataprivay@atyeti.com">
                  atyeti-dataprivay@atyeti.com
                </a>
                <br />
                Effective Date:06/03/2021
              </blockquote>
              <p>
                This Privacy Statement is effective from May 2018 and it
                supersedes all existing polices on the subject matter.
              </p>
              <h3>Changes to this Privacy Statement:</h3>
              <p>
                Please note that this Privacy Statement may be subject to change
                from time to time. The revised Privacy Statement will
                accordingly be published on this page. &nbsp;We will not reduce
                your rights under this Privacy Statement without your explicit
                consent. Please regularly check this Privacy Statement to ensure
                you are aware of the latest updates with respect to the same.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    </>
  )
}

export default PrivacyPolicy
